import React, {useCallback, useEffect, useRef, useState} from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFaceSmile, faPaste} from "@fortawesome/free-regular-svg-icons";
import {faArrowUpRightFromSquare, faMicrochip, faXmark, faLanguage} from "@fortawesome/free-solid-svg-icons";
import {setReplySent, setShowEmojiModal} from "../../../actions/ui";
import {useDispatch, useSelector} from "react-redux";
import {editReply, sendReply, setCommentReview} from "../../../actions/requests";
import Button from "../Button";
import "./ReplyForm.scss"
import SavedRepliesModal from "../SavedRepliesModal";
import EmojiContainer from "../EmojiContainer";
import {addNewTmpComment, updateComment} from "../../../actions/pages";
import {capitalize, errorNotify} from "../../../utils/app";
import TagInput from "../TagInput";
import axios from "axios";
import {CSRF} from "../../../constants";
import AiAction from "./AiAction";
import Translate from "../CommentItem/Actions/Translate";
// import aiIcon from "images/ai.svg"


const ReplyForm = ({comment, message}) => {
    const dispatch = useDispatch()
    const privateRef = useRef()
    const msgRef = useRef()
    const instructRef = useRef()
    const {author_name, author_id, can_reply_privately, page_id, page_type, reviewed, replyEdit} = comment.attributes
    const replySent = useSelector(state => state.ui.replySent)
    const whitelist = [{ value: author_id, text: author_name}]
    const [showSavedReplies, setShowSavedReplies] = useState(false)
    const [tagShow, setTagShow] = useState(false)
    const [tagAdded, setTagAdded] = useState(false)
    const [instructionsShow, setInstructionsShow] = useState(false)

    const dropdownSettings = {
        enabled: 0,
        position: 'text',
        mapValueTo: 'text',
        highlightFirst: true,
        caseSensitive: true,
        maxItems: 1
    }

    useEffect(()=>{
        msgRef.current.DOM.input.focus()
    },[])

    const beforePasteHook = (e, t) => {
        const {tagify, pastedText, clipboardData} = t
        document.execCommand("InsertText", false, pastedText);
        return Promise.reject()
    }

    const tagSettings = {
        mode: "mix",
        pattern: /^@| @/,
        tagTextProp: 'text',
        dropdown : dropdownSettings,
        enforceWhitelist: true,
        skipInvalid: true,
        whitelist: whitelist,
        hooks: { beforePaste: beforePasteHook},
        callbacks: {"dropdown:show": (e) => setTagShow(true),
            "dropdown:hide": (e) => setTagShow(false),
            "remove": (e) => setTagAdded(false),
            "add": (e) => setTagAdded(true)}
    }

    const handleSubmit = (e, archive=false) => {
        e.preventDefault()
        if(!replySent.includes(comment.id) || replyEdit){
            !replyEdit && dispatch(setReplySent(comment.id))
            const value = msgRef.current.state.lastOriginalValueReported
            if(value) {
                const formData = new FormData()
                formData.append("comment[message]", value)
                privateRef.current && formData.append("private", privateRef.current.checked)
                if(replyEdit){
                    dispatch(editReply(comment.id, formData, page_id, replyEdit.id))
                    dispatch(updateComment(comment.id, {replyEdit: null}))
                }else{
                    dispatch(sendReply(comment.id, formData, page_id))
                }
                if(archive){
                    if(!reviewed){
                        dispatch(addNewTmpComment(comment.id))
                    }
                    dispatch(setCommentReview(comment.id, reviewed, page_id))
                }
            }else{
                errorNotify("Please fill in the reply field.")
            }
        }
    }

    const setMessage = (message) => {
        msgRef.current.DOM.input.focus()
        const range = window.getSelection().getRangeAt(0)
        const old_range = msgRef.current.state.selection.range || range
        range.setStart(old_range.startContainer, old_range.startOffset)
        range.setEnd(old_range.endContainer, old_range.endOffset)
        range.collapse(old_range.collapsed)
        document.execCommand("InsertText", false, message);
    }

    const closeForm = () =>{
        dispatch(updateComment(comment.id, {replyEdit: null}))
        dispatch(updateComment(comment.id, {show_reply_form: false}))
    }

    const cursor_position = (e) => {
        const sel = document.getSelection();
        sel.modify("extend", "backward", "paragraphboundary");
        const pos = sel.toString().length;
        if(sel.anchorNode != undefined) sel.collapseToEnd();
        return pos;
    }

    const handleEmojiSelect = (emoji) => {
        setMessage(emoji.native)
        cursor_position()
    }

    const handlePrivate = (e) => {
        if(e.target.checked){
            msgRef.current.removeTags(author_name, true)
            msgRef.current.settings.pattern = /efgbhe3qwedvbth564y6u654475gbgdnty64y656u/
        }else{
            msgRef.current.settings.pattern = / @/
        }
    }

    const handleInstructions = (e) => {
        setInstructionsShow(!instructionsShow)
        console.log('handleInstructions', instructionsShow)
    }

    return (
        <>
            <div className="col-12 mt-2 reply-collapse no-transition collapse show">
                <div className="reply-block mb-3">
                    <div className="w-100" >
                        <div className="text-group w-100 mb-2">
                            <span className="close-sm">
                                <Button type="reset" className="btn btn-tr p-0" clickHandler={closeForm}>
                                    <FontAwesomeIcon icon={faXmark}
                                                     className="saved-replies-icon mx-2"
                                                     size="lg"/>
                                </Button>
                            </span>
                            <TagInput placeholder="Type your comment here…" name="comment[message]"
                                      tags={replyEdit?.attributes?.message}
                                      settings={tagSettings}
                                      tagifyRef={msgRef}/>
                        </div>

                        <div className="d-flex justify-content-between mx-0 reply-bottom">
                            <div className="d-flex reply-p p-0">
                                {can_reply_privately && (replyEdit ? replyEdit?.attributes?.can_edit : true) && <div className="private-checkbox">
                                    <input className="form-check-input mt-0" type="checkbox" value="" onChange={handlePrivate} ref={privateRef}/>
                                    <label className="form-check-label ms-2">
                                        Send as private message
                                    </label>
                                </div>}
                            </div>

                            <div className="d-flex reply-b p-0 icon-buttons position-relative">

                                <FontAwesomeIcon icon={faPaste}
                                                 className="saved-replies-icon mx-2"
                                                 size="lg"
                                                 onClick={()=>{setShowSavedReplies(true)}}/>

                                 <Translate pageId={page_id} objectId={comment.id} isMobile={true}
                                            className="btn btn-tr saved-replies-icon p-0 mx-2"
                                            inputRef={msgRef}/>

                                <FontAwesomeIcon icon={faFaceSmile}
                                                 className="emoji-icon mx-2"
                                                 size="lg"
                                                 onClick={()=>{dispatch(setShowEmojiModal(null, "comment_message"))}}/>

                                {!showSavedReplies && <EmojiContainer className="emoji-wrapper" handleEmojiSelect={handleEmojiSelect}/>}

                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-between mb-2">
                    <div className="d-flex">
                        <AiAction objectId={comment.id} pageId={page_id} msgRef={msgRef} instructRef={instructRef} setMessage={setMessage}/>
                    </div>
                    <div className="d-flex reply-p p-0">
                        <Button type="reset" className="btn btn-tr close-lg" clickHandler={closeForm}>Cancel</Button>
                        <Button type="submit" className="btn btn-secondary mx-2" clickHandler={(e)=>{handleSubmit(e,true)}}>Reply and archive</Button>
                        <Button type="submit" className="btn btn-primary" clickHandler={handleSubmit}>Reply</Button>
                    </div>
                </div>
                <div className="add-instructions">

                    {instructionsShow ?
                        <>
                            <div className="instruct-link" onClick={handleInstructions}>Remove additional instructions</div>
                            <textarea className="form-control"
                                      placeholder="Mention the 30% off sale we’re currently running"
                                      rows="3"
                                      ref={instructRef}
                                      name="comment[instructions]"></textarea>
                        </>
                        : <>
                            <div className="instruct-link" onClick={handleInstructions}>Add additional instructions</div>
                        </>
                    }

                </div>
            </div>

            {showSavedReplies && <SavedRepliesModal setShowSavedReplies={setShowSavedReplies} pageId={page_id}
                                                    setMessage={setMessage}/>}
        </>
    )
}

export default React.memo(ReplyForm)